<template>
  <div class="page-component__scroll">
    <video-wrapper></video-wrapper>
    <solution-wrapper></solution-wrapper>
<!--    <schema-wrapper></schema-wrapper>-->
    <system-warpper></system-warpper>
    <footer-warpper></footer-warpper>
    <div class="back-top">
      <div @click="backTop" v-show="showBtn" class="back-top__inner back flex-column">
        <img src="http://www.iwincloud.com/webui-saas-website/img/icon-back.b7f33ff5.svg"/>
        <span>顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
import NewHeader from "@/components/common/newHeader.vue";
import solutionWrapper from "@/components/newMore/solutionWrapper.vue";
import SchemaWrapper from "@/components/newMore/schemaWrapper.vue";
import SystemWarpper from "@/components/newMore/systemWarpper.vue";
import FooterWarpper from "@/components/newMore/footerWarpper.vue";
import VideoWrapper from "@/components/newMore/videoWrapper.vue";

export default {
  name: "newMore",
  components: {VideoWrapper, FooterWarpper, SystemWarpper, SchemaWrapper, solutionWrapper, NewHeader},
  data() {
    return {
      showBtn: false,
    }
  },
  methods: {
    // 回到顶部
    showbtn() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 100) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },


    backTop() {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) { clearInterval(timer) }
      }, 10);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true)
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
.page-component__scroll {
  position: relative;
  //width: 100%;
  //height: 100%;
  //overflow: hidden;
  //background: #080f2d;
  //box-sizing: border-box;
}
.header{
  position: relative;
}
.back-top {
  position: fixed;
  z-index: 5;
  color: #fff;
  bottom: 2.5rem;
  right: 2.5rem;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  div {
    margin-bottom: 1.875rem;
  }
  .back-top__inner {
    width: 3.75rem;
    height: 3.75rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #2d5afa;
    cursor: pointer;
    img{
      width: 1.375rem;
    }
    .back-top__inner--value {
      margin-top: 0.3125rem;
      font-size: 1rem;
    }
  }
}
</style>