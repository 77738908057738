<template>
<div>
  <div class="videoContainer">
    <new-header></new-header>
    <video class="fullscreenVideo" id="bgVid" playsinline="" autoplay="autoplay" muted="" loop="loop">
      <source src="../../assets/video/index.mp4" type="video/mp4">
    </video>
    <div class="video-info">
      <div class="video-title">不受时空限制的医疗服务，让健康护航更加便利</div>
<!--      <div class="video-sub"> 1天实现信息化，业务/管理翻 6 倍</div>-->
      <div class="video-button">
        <span @click="videoTiyan" class="button_right">立即体验</span>
      </div>
    </div>
  </div>
  <div class="plan_warpper">
    <div class="container flex-column">
      <div class="custom-title">
        <h1>互联网医院</h1>
        <div class="tag"></div>
        <div>ADVANTAGES OF THE SCHEME</div>
      </div>
      <div class="plan-list">
        <ul class="plan-list__nav">
          <li @click="liIndex=index" v-for="(item,index) in planList" :class="liIndex==index?'is_active':''">{{item.navName}}</li>
        </ul>
        <ul class="plan-list__plans">
          <li v-for="(item,index) in planList[liIndex].list">
            <div class="title">{{item.title}}</div>
            <div class="info" v-if="item.contxt">{{item.contxt}}</div>
            <div class="info" v-if="item.img">
              <img :src="item.img"/>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import NewHeader from "@/components/common/newHeader.vue";

export default {
  name: "videoWrapper",
  components: {NewHeader},
  data(){
    return{
      planList:[
        {
          navName:'岳泰兴互联网医院',
          list:[{
            title:'岳泰兴互联网医院',
            contxt:'岳泰兴互联网医院是建立以医院为主体的互联网医疗服务平台，构建“线上预诊-线下面诊与治疗-线上复诊”的新型医疗模式。提供云诊室，方便医生在院外不同环境中利用互联网医院平台进行诊疗服务，充分利用“互联网+”，实现多渠道预约、智能导诊、挂号、账单支付、查询、医保支付、评价等基本服务，以“数据多跑路，患者少跑路”为核心，实现脱卡就诊，手机医保支付等全新服务'
          },
          ]
        },
        {
          navName:'产品框架',
          list:[
              {
            title:'产品框架',
            img:require('../../static/plan/plan_01.png')
          },
          ]
        }, {
          navName:'产品特性',
          list:[{
            title:'创新诊疗模式',
            contxt:'线上线下协同服务，远程诊疗开药，诊闻流程全智能移动化。'
          },
            {
              title:'打造健康生态圈',
              contxt:'开创医疗与社会药房、医保、商保、医学诊断机构、物流配送等第三方全面合作运营模式创建互联网+医疗健康生态圈。'
            },
            {
              title:'集成化',
              contxt:'统一入口，统一基础支撑，支持不同应用接入。'
            },
            {
              title:'安全保障性',
              contxt:'严格授权管理、定期自查检测、数据安全备份'
            },
          ]
        },
      ],
      liIndex:0,
    }
  },
  methods:{
    videoTiyan(){
        this.$router.push('/contactUs')
    }
  }
}
</script>

<style scoped lang="scss">
.videoContainer {
  position: relative;
  //width: 100%;
  //height: 100vh;
  width: auto;
  height: 990px;
  overflow: hidden;
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 990px;
  left: 0;
  top: 0;
  background: #080f2d;
  opacity: .6;
  z-index: 5;
}

.fullscreenVideo {
  width: 100%;
  height: 100%;
  object-fit: fill
}

.plan_warpper {
  position: relative;
  width: auto;
  height: 990px;
  background-image: url(http://www.iwincloud.com/webui-saas-website/img/bg-plan.ceff1252.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;


  .plan-list {
    //min-height: 800px;
    height: 100%;
    overflow: hidden;
    align-items: center;
    display: flex;

    .plan-list__nav {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        padding: 0 5rem 0 3.4375rem;
        height: 5.5rem;
        line-height: 5.5rem;
        text-align: left;
        font-size: 1.5rem;
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: 1.875rem;
      }

      li:before,li:after {
        content: "";
        position: absolute;
        transition: all .3s;
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
      }
      .is_active:before {
        width: 100%;
        background-image: linear-gradient(87deg,rgba(24,120,228,0),#5175f4);
        opacity: .5;
      }
      .is_active:after {
        width: 0.25rem;
        background: #2d5afa;
      }
    }
    .plan-list__plans{
      height: 90%;
      //flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      list-style: none;
      padding: 0;
      color: #fff;
      margin: 0 0 0 8.75rem;
      .title:before {
        content: "";
        position: absolute;
        width: 0.3125rem;
        height: 0.3125rem;
        background: #fff;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      .title{
        position: relative;
        padding-left: 0.9375rem;
        font-size: 2.25rem;
        margin-bottom: 1.875rem;
      }
      .info{
        font-size: 1.125rem;
        line-height: 2.125rem;
        letter-spacing: .0625rem;
      }
    }
  }
}

.video-info {
  width: 50.625rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;

  .video-title {
    font-size:60px;
    color: #fff;
    line-height: 6rem;
    letter-spacing: .214375rem;
    text-align: center;
  }

  .video-sub {
    margin-top: 1.875rem;
    font-size: 1.5rem;
    color: #a4b3d6;
  }

  .video-button {
    margin-top: 6.5625rem;
    text-align: center;

    .button_right {
      color: #ffffff;
      background-color: #2D5AFA;
      padding: 1.1875rem 4.4375rem;
      font-size: 1.5rem;
      cursor: pointer;
      z-index: 999!important;
    }
    .button_right:hover{
      background-color: #2D5AFA!important;
    }
  }
}
.is_active{
  font-weight: 700;
}
</style>