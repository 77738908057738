<template>
  <div class="solution_father">
    <div class="container flex-column">
      <div class="custom-title">
        <h1>智慧医院</h1>
        <div class="tag"></div>
        <div>CORE SOLUTIONS</div>
      </div>
      <div class="solution-list flex-row">
        <ul class="solution-list__nav">
          <li @click="liIndex=index" :class="liIndex==index?'is_active':''" v-for="(item,index) in solutionList">
            <img style="width: 50px;height:50px;" :src="item.src">
            <div>{{ item.navName }}</div>
          </li>
        </ul>
        <div class="solution-list__details" v-for="(item,index) in solutionList[liIndex].list">
          <div class="details_title">{{ item.title }}</div>
          <div class="details_info" v-if="item.contxt" v-html="item.contxt"></div>
          <div class="detials_info" v-if="item.img">
            <img :src="item.img"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "solutionWrapper",
  data() {
    return {
      liIndex: 0,
      solutionList: [
        {
          src: require('../../assets/img/hospital.png'),
          navName: '岳泰兴智慧医院',
          list: [
            {
              title: '围绕三个点做出信息化的全面解决方案',
              contxt: `<p>第一：是面向医务人员的“智慧医疗”，为医务人员提高医疗效率</p>
                        <p style="margin: 60px 0">第二：是面向患者的“智慧服务”，为患者解决看病难、效率低的难点，提高看病效率</p>
                         <p>第三：是面向医院的“智慧管理”，用于医院精细化的信息化智能管理</p>`
            }
          ]
        },
        {
          navName: '产品框架',
          src: require('../../assets/img/kuangjia.png'),
          list: [{
            title: '产品框架',
            img: require('../../static/plan/plan_02.png')
          }
          ]
        }, {
          src: require('../../assets/img/texing.png'),
          navName: '产品特性',
          list: [
            {
              title: '产品特性',
              contxt: `<p>第一：是面向医务人员的“智慧医疗”，为医务人员提高医疗效率</p>
                        <p style="margin: 60px 0">全面智能化：医疗、管理、运营、决策、行政、后勤</p>
                         <p style="margin: 0 0 60px 0">全面资源优：人力、物力、财力、医疗信息</p>
                         <p>服务管理与决策：AI智能决策支撑系统</p>`
            }
          ]
        },],
    }
  }
}
</script>

<style scoped lang="scss">
.solution_father {
  position: relative;
  height: 990px;
  width: auto;
  background-image: url(http://www.iwincloud.com/webui-saas-website/img/bg-solution.f10d19f2.png);
  background-size: cover;
  background-repeat: no-repeat;

  .solution-list {
    flex: 1;
    overflow: hidden;
    align-items: center;
    color: #fff;
  }

  .solution-list__nav {
    width: 25.75rem;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      text-align: center;
      padding: 3.125rem 0;
      cursor: pointer;
      font-size: 1.5rem;
      letter-spacing: .028125rem;
      background: hsla(0, 0%, 100%, .1);

      img {
        margin-bottom: 0.9375rem;
      }
    }
  }

  .solution-list__details {
    flex: 1;
    margin-left: 6.75rem;

    .details_title {
      font-size: 2.25rem;
      margin-bottom: 3.75rem;
    }

    .details_info {
      font-size: 21px;
      letter-spacing: .04625rem;
      line-height: 2.125rem;
    }
  }
}

.is_active {
  background: linear-gradient(135deg, #5993fd, #2d5afa) !important;
}
</style>