<template>
  <div class="welcome-footer flex-column">
    <div class="footer-experience flex-column">
      <span class="experience-title">免费获取解决方案</span>
      <button class="sass_button" @click="toContactUs">立即获取</button>
    </div>
<!--    <footer class="footer-inner flex-column">-->
<!--      <div class="container container-pc flex-column">-->
<!--        <ul class="website-nav flex-row">-->
<!--          <li>-->
<!--            <div class="website-nav">关于卫宁</div>-->
<!--            <div> 公司介绍</div>-->
<!--            <div> 企业文化</div>-->
<!--            <div> 合作伙伴</div>-->
<!--            <div> 企业荣誉</div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="website-nav">集团动态</div>-->
<!--            <div> 卫宁新闻</div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="website-nav">产品服务</div>-->
<!--            <div> WINEX</div>-->
<!--            <div> 智慧医院</div>-->
<!--            <div> 智慧卫生</div>-->
<!--            <div> 互联网+健康</div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="website-nav">案例中心</div>-->
<!--            <div> 医院案例</div>-->
<!--            <div> 卫生案例</div>-->
<!--            <div> 互联网案例</div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="website-nav">投资者关系</div>-->
<!--            <div> 上市公司公告</div>-->
<!--            <div> 深交所互动易</div>-->
<!--            <div> 投资者关系互动平台</div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </footer>-->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/common/Footer.vue";

export default {
  name: "footerWarpper",
  components: {Footer},
  methods:{
    toContactUs(){
      this.$router.push('/contactUs')
    }
  },
}
</script>

<style scoped lang="scss">
.welcome-footer {
  position: relative;
  height: 500px;
  width: auto;

  .footer-experience {
    height: 16.25rem;
    align-items: center;
    background: #080f2d;
    //background-image: url(http://www.iwincloud.com/webui-saas-website/img/bg-footer.82907a7c.png);
    //background-size: cover;
    //background-repeat: no-repeat;
    justify-content: center;

    .experience-title {
      margin-bottom: 1.875rem;
      color: #fff;
      font-size: 2.5rem;
      letter-spacing: .1025rem;
    }

    .sass_button {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: transparent;
      border: 0.0625rem solid #fff;
      color: #fff;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      transition: .1s;
      font-weight: 500;
      padding: 1rem 2.0625rem;
      font-size: 1.375rem;
      border-radius: 0.5rem;
    }
    .sass_button:hover{
      color: #5175f4!important;
      border:0.0625rem solid #5175f4!important;
    }
  }

  .container-pc {
    flex: 1;
    justify-content: center;

    .website-nav {
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;
      color: #fff;
      font-size: 1rem;

      li {
        cursor: pointer;

        .website-nav {
          opacity: .5;
        }

        div {
          margin-top: 30px;
        }
      }
    }
  }
}

.footer-inner {
  background: #080f2d;
  height: 450px;
}
</style>