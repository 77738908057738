<template>
  <div class="system_father">
    <div class="container flex-column">
      <div class="custom-title">
        <h1>其他产品体系</h1>
        <div class="tag"></div>
        <div>PRODUCT SYSTEM</div>
      </div>
      <div class="container system-list flex-row">
        <div class="system-item flex-column" v-for="(item,index) in productList" @mouseenter="hoverIndex = index"
             @mouseleave="hoverIndex=null">
          <div class="system-item--top"></div>
          <div class="system-item--title">{{ item.title }}</div>
          <div class="system-inner flex-column">
            <div class="system-item--content" v-for="(it,idx) in item.list">{{ it }}</div>
            <div class="system-item--content">. . . . . .</div>
          </div>
          <div class="system_hover flex-column" v-show="hoverIndex==index">
            <div class="button_div button_tiyan" @click="toContactUs">立即咨询</div>
<!--            <div class="button_div button_info">产品详情</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "systemWarpper",
  data() {
    return {
      hoverIndex: null,
      productList: [
        {
          title: '智慧养老',
          list: ['居家养老', '机构养老', '家庭病床', '康复管理', '一键报警', '定时定位', '膳食管理', '护理管理',]
        },
        {
          title: '药店管理系统',
          list: ['进销存管理', '财务管理', '会员管理', '效期管理', '往来管理', '序列号管理', '组合码自动识别', '往来管理',]
        },
        {
          title: 'HIS系统',
          list: ['门诊医生护士工作站', '电子处方', '财务管理', '药房管理', '住院管理', '权限管理', '基础数据维护', '大数据分析',]
        },
        {
          title: '医疗设备',
          list: ['核磁共振', 'CT(COMPUTED TOMOGRAPHY)', 'DR(数字X射线成像系统)', '四维多功能牵引床', '体外冲击波治疗仪', '吞咽神经和肌肉电刺激仪', '颈椎牵引机', '低频电子脉冲治疗仪',]
        },
      ]
    }
  },
  methods:{
    toContactUs(){
      this.$router.push('/contactUs')
    }
  }
}
</script>

<style scoped lang="scss">
.system_father {
  position: relative;
  height: 990px;
  width: auto;
  padding: 0 0 50px 0;
  background-image: linear-gradient(180deg, #1f1fa1, #17054c);

  .system-list {
    position: relative;
    flex: 1;
    overflow: hidden;
    color: #fff;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-around;

    .system-item {
      position: relative;
      margin-left: 20px;
      width: 410px;
      height: 650px;
      background-image: linear-gradient(135deg, #233aa7, #270aa2);
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      color: #fff;

      .system-item--top {
        width: 100%;
        height: 0.5rem;
        background-image: linear-gradient(180deg, #e4edff, #93b6ff);
      }

      .system-item--title {
        height: 6rem;
        line-height: 6rem;
        background-image: linear-gradient(129deg, rgba(48, 35, 174, .23), rgba(83, 160, 253, .23) 25%, rgba(108, 81, 236, .23));
        font-size: 2rem;
      }

      .system-inner {
        flex: 1;
        overflow: hidden;

        .system-item--content {
          position: relative;
          font-size: 1.25rem;
          font-weight: 100;
          text-align: left;
          padding-left: 5.375rem;
          margin-top: 1.5rem;
        }

        .system-item--content:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 3.5rem;
          transform: translateY(-50%);
          width: 0.625rem;
          height: 0.625rem;
          border-radius: 50%;
          border: 0.125rem solid #50e3c2;
        }
      }

      .system_hover {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background: rgba(0, 0, 0, .6);

        .button_div {
          font-size: 1.375rem;
          padding: 0.875rem 4.6875rem;
          font-weight: 400;
          border-radius: 0.25rem;
        }

        .button_tiyan {
          color: #fff;
          background-color: #2d5afa;
        }

        .button_tiyan:hover {
          background: #5175f4 !important;
        }

        .button_info {
          margin-top: 2.125rem;
          color: #fff;
          border: 1px solid #fff;
        }

        .button_info:hover {
          color: #5175f4 !important;
          border: 1px solid #5175f4 !important;
        }
      }
    }
  }
}
</style>