<template>
  <div class="schema_father">
    <div class="container flex-column">
      <div class="custom-title">
        <h1>产品架构</h1>
        <div class="tag"></div>
        <div>PRODUCT ARCHITECTURE</div>
      </div>
      <div class="schema-ppt flex-column">
        <img src="http://www.iwincloud.com/webui-saas-website/img/bg-schema.f784e2cf.svg"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schemaWrapper"
}
</script>

<style scoped lang="scss">
.schema_father{
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 0 50px 0;
  background-image: linear-gradient(180deg,#110d7e,#3832b2);
  .schema-ppt{
    margin:0 auto;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
      height:100%;
    }
  }
}
</style>