import { render, staticRenderFns } from "./schemaWrapper.vue?vue&type=template&id=69650d91&scoped=true&"
import script from "./schemaWrapper.vue?vue&type=script&lang=js&"
export * from "./schemaWrapper.vue?vue&type=script&lang=js&"
import style0 from "./schemaWrapper.vue?vue&type=style&index=0&id=69650d91&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69650d91",
  null
  
)

export default component.exports